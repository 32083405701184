@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
    margin: 0;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    overflow-x: hidden;
}

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
}

a {
    text-decoration: none !important;
}

html {
    scroll-behavior: smooth;
}

img {
    max-width: 100%;
}



a {
    text-decoration: none;
    transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
}

a,
a:hover,
a:active,
a:focus {
    text-decoration: none;
    outline: none;
}


button {
    /* WebKit */
    -webkit-appearance: none;

    /* Mozilla */
    -moz-appearance: none;

    /* Opera */
    -o-appearance: none;

    /* Internet Explorer */
    -ms-appearance: none;

    /* CSS3 */
    appearance: none;
}

.titleh2 {
    margin: 0px 0px 20px;
}


.titleh2 h2 {
    color: #000000;

    font-size: 38px;
    font-weight: 600;
}

.titleh2 h2 span {
    color: #36884C;
}

p {
    color: #4C4C4C;
    font-size: 16px;
}

h4 {
    font-size: 20px;
    color: #000000;
    font-weight: 600;
}









.cta {
    text-decoration: none;
    transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
    background-color: #36884C;
    padding: 12px 25px;
    border-radius: 8px;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    display: table;
}

.cta:hover {
    background-color: #000000;
    color: #fff;
}

/*=====header=====*/
.HeaderInner .navbar-brand {
    margin: 0px;
    padding: 0px;

}

.HeaderInner .navbar-brand img {
    width: 200px;
}

.Togglebar {
    display: flex;
    align-items: center;
    gap: 15px;
}

header {
    background-color: #ffffff;
    padding: 5px 0px 10px;
}

.HeaderInner ul li a {
    padding: 10px 14px;
    color: #000000;
    font-size: 16px;
    font-weight: 500;
    position: relative;
    transition: all 0.3s;
    display: block;
}

/* .HeaderInner ul li a.active {
    font-weight: 600;
} */

.HeaderInner .dropdown-menu {
    border-radius: 4px;
    background-color: #fff;
    min-width: 180px;
    padding: 0px 0px;
    border: 0px;
    transition: all ease .3s;
}

.dropdown-item.active,
.dropdown-item:active {
    background-color: #36884C;
}

.HeaderInner ul li a:hover {
    color: #36884C;
}

.HeaderInner .dropdown-menu li a {
    padding: 7px 10px !important;
    border-bottom: 1px solid #dbddbd;
    font-size: 15px;
    overflow: hidden;
    display: block;
}

.HeaderInner .dropdown-menu li:last-child a {
    border-bottom: 0px;
}

.dropdown-toggle::after {
    border: 0px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='7' viewBox='0 0 12 7' fill='none'%3E%3Cpath d='M6 6.78207L0 1.28309L1.4 0L6 4.21588L10.6 0L12 1.28309L6 6.78207Z' fill='black'/%3E%3C/svg%3E");
    width: 16px;
    min-width: 16px;
    height: 16px;
    min-height: 16px;
    background-repeat: no-repeat;
    background-position: center center;
    top: 52%;
    transform: translateY(-52%);
    transition: all ease .3s;
    position: absolute;
    right: 0px;
    border: 0px !important;
}

li.dropdown .dropdown-toggle {
    padding-right: 20px;
    margin-right: 14px;
}



.headerctagroup {
    display: flex;
    align-items: center;
    margin-left: 30px;
}

.navbar-toggler:focus {
    box-shadow: none !important;
}


/*=====header=====*/





/*=====Banner=======*/

.HeroBanner {
    /* background-color: #36884C; */
    padding: 80px 0px 110px;
    margin: 0px 0px 25px;
    position: relative;
    background-image: url(../../public/images//bannerlayerimg1.png);
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: cover;
}


.HeroBanner .cta {
    background-color: #fff;
    color: #000000;
}

.HeroBanner .cta:hover {
    background-color: #000000;
    color: #fff;
}


.twocolumnrow {
    display: flex;
    gap: 15px;
    align-items: center;
    justify-content: space-between;
}

.ContentSec {
    width: calc(50% - 8px);
    min-width: calc(50% - 8px);
    padding-bottom: 130px;
}

.ImgSection {
    width: calc(50% - 8px);
    min-width: calc(50% - 8px);
}

.HeroBanner h1 {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 60px;
    color: #ffffff;
    margin: 0px;
}

.HeroBanner p {
    color: #ffffff;
    margin: 20px 0px 30px;
    max-width: 400px;
}

.ImgLayeranimation {
    position: relative;
    overflow: hidden;
}

.ImgLayeranimationIn {
    max-width: 500px;
    margin-left: auto;
}

.ImgLayeranimationInner {
    width: 500px;
    min-width: 500px;
    height: 500px;
    min-height: 500px;
    position: relative;
    text-align: right;
    border-radius: 50%;
}

.Imgback {
    position: relative;
}

.Imgback {
    position: absolute;
    top: 0;
}

.Img1 {
    position: relative;
    right: 16px;
    top: 0px;
}


.Img1 img {
    max-width: 480px;
}

.Img2 img {
    max-width: 330px;

}

.Img3 img {
    max-width: 250px;
}

.Img2 {
    position: absolute;
    right: 75px;
    bottom: 15px;
}

.Img2 img {
    animation-fill-mode: both;
    animation: blinker 1.5s ease-in-out infinite;
    -webkit-animation: blinker 1.5s ease-in-out infinite;
    -moz-animation: blinker 1.5s ease-in-out infinite
}


@keyframes blinker {
    0% {
        transform: scale(1, 1);
    }

    50% {
        transform: scale(1.03, 1.03);
    }

    100% {
        transform: scale(1, 1);
    }
}



.Img3 {
    position: absolute;
    right: 104px;
    bottom: 30px;
}

.Img3 img {
    animation-fill-mode: both;
    animation: blinker 1.5s ease-in-out infinite;
    -webkit-animation: blinker 1.5s ease-in-out infinite;
    -moz-animation: blinker 1.5s ease-in-out infinite
}

.ImgFront {
    position: absolute;
    top: 0;
    left: -10px;
    max-width: 467px;
}





/*=====Banner=======*/




/*===MoneyComplicated====*/
.MoneyComplicatedrow {
    display: flex;
    align-items: center;
}

.MoneyComplicatedLhs {
    width: calc(40% - 50px);
    min-width: calc(40% - 50px);
    margin-right: 50px;
}

.backcurcleimg img {
    animation-fill-mode: both;
    animation: blinker 1.5s ease-in-out infinite;
    -webkit-animation: blinker 1.5s ease-in-out infinite;
    -moz-animation: blinker 1.5s ease-in-out infinite;
}

.MoneyComplicatedRhs {
    width: 60%;
    min-width: 60%;
}


.backcurcleimg img {
    width: 100%;
}

.MoneyComplicatedImg {
    position: relative;
}

.FrontImage img {
    width: 100%;
}

.FrontImage {
    position: absolute;
    bottom: 0;
}

.MoneyComplicatedRhs .cta {
    margin: 25px 0px 0px;
}

.MoneyComplicated {
    background-color: #ffffff;
    padding: 30px 0px 110px;
}

.tophd {
    margin: 0px 0px 50px;
}

/*===MoneyComplicated====*/

/*===Rulesforstress====*/
.Rulesforstress {
    background-image: url(../../public/images//rulesbg.png);
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
    padding: 130px 0px 90px;
}

.Rulesforstress .tophd .titleh2 h2 span,
.Rulesforstress .tophd p {
    color: #fff;
}

.Rulesforstressrow {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 40px;
}

.FlipBoxparent {
    width: 100%;
    min-height: 430px;
    perspective: 1000px;
    overflow: hidden;
    border-radius: 40px;
    box-shadow: 10px 10px 4px 0px #36884C;
    -webkit-transition: all 0.5s ease-in-out;
}


.Flipbox {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;

}

.FlipboxFront {
    background-color: #ffffff;
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s ease 0s;
    border-radius: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 15px;
}

.FlipboxFront,
.OverlayText {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.FlipboxIcon {
    max-width: 300px;
    margin: 0 auto 20px;
}

.Flipbox h4 {
    text-align: center;
    padding: 0px 15px;
    margin: 0px;
    font-size: 26px;
}

.Flipbox h4 a {
    color: #000000;
}

.OverlayText {
    border-radius: 40px;
    background-color: #36884C;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotateY(180deg);
}

.OverlayText p {
    padding: 0px 15px;
    max-width: 400px;
    margin: 0 auto;
    color: #ffffff;
    left: 0;
    right: 0;
    text-align: center;
    /* -webkit-line-clamp: 3;
    -webkit-box-orient: vertical; */
    /* overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box; */
    min-height: 74px;
    font-size: 21px;
}




/* .Flipbox:hover .OverlayText {
    height: 100%;
} */

.FlipBoxparent:hover {
    box-shadow: 10px 10px 4px 0px #FFF;
    -webkit-transition: all 0.5s ease-in-out;

}

.flip-box:hover .flip-box-inner {
    transform: rotateY(180deg);
}

.FlipBoxparent:hover .Flipbox {
    transform: rotateY(180deg);
}

/*===Rulesforstress====*/




/*HowItWorksection*/
.HowItWorksection {
    padding: 90px 0px;
}

.HowItWorksectionrow {
    display: flex;
    justify-content: space-between;
}



.HowItWorkLhs {
    width: calc(60% - 50px);
    min-width: calc(60% - 50px);
    margin-right: 50px;
}

.HowItWorkRhs {
    width: calc(50% - 50px);
    min-width: calc(44% - 50px)
}


.nabImgBack img {
    animation-fill-mode: both;
    animation: blinker 1.5s ease-in-out infinite;
    -webkit-animation: blinker 1.5s ease-in-out infinite;
    -moz-animation: blinker 1.5s ease-in-out infinite;
}

/* .HowItWorkRhs .nabImgBack img {
    opacity: 0;
} */



.HowItWorkiconText {
    display: flex;
    align-items: center;
    margin: 0px 0px 40px;
    max-width: 430px;
}


.HowItWorkiconText p {
    margin: 0px;
}

.HownabIcon {
    width: 140px;
    min-width: 140px;
    margin: 0px 30px 0px 0px;
}

.HownabIcon img {
    max-width: 140px;
}

.nabImg {
    position: relative;
}

.nabImgFront {
    position: absolute;
    bottom: -40px;
    max-width: 299px;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
}


.nabImgFront img {
    height: 400px;
    object-fit: cover;
    width: auto;
    /* border-radius: 20px;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, .16);
    border: 2px solid #36884C; */
}

.HowItWorksection .titleh2 {
    margin: 0px 0px 40px;
}

.app-store {
    display: flex;
    align-items: center;
    margin-top: 66px;
    gap: 10px;
    justify-content: center;
}

.app-store .app-store-icons {
    max-width: 200px;
    width: 50%;
}

/*HowItWorksection*/


/*WhyNA*/
.WhyNAB {
    background-color: #ffffff;
    padding: 0px 0px 50px;
}

.WhyNABrow {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
}

.WhyNAB .titleh2 {
    margin: 0px 0px 50px;
}

.shadowBox {
    border-radius: 40px;
    background: #FFF;
    box-shadow: 0px 0px 9px 3px rgba(0, 0, 0, 0.14) inset;
    padding: 25px 20px;
    text-align: center;
    position: relative;
}

.shadowBox h4 {
    margin: 20px 0px 15px;
}

.shadowBox p {
    margin: 0px;
}

.ShadowIcon {
    border-radius: 50px;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, .16);
    background-color: #fff;
    width: 101px;
    min-width: 101px;
    height: 101px;
    min-height: 101px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
}


/*WhyNA*/


/*footer*/
.footerIn {
    background-image: url(../../public/images//footerbg.png);
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
    padding: 210px 0px 20px;
}

.footerTop {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    align-items: center;
}

.flogo {
    width: calc(40% - 20px);
    min-width: calc(40% - 20px);

}

.flogo a img {
    max-width: 140px;
    display: none;
}

.subcribeform {
    width: 60%;
    min-width: 60%;
}

.subcribeform form {
    display: flex;
    align-items: flex-start;
    position: relative;
    justify-content: flex-end;
    gap: 15px;
}

.subcribeform form input {
    height: 55px;
    border: 1px solid #C0C0C0;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 16px;
    border-radius: 0px;
    background-color: #ffffff;
    border-radius: 5px;
    outline: none;
    width: calc(100% - 220px);
    font-weight: 400;
    color: #202020;
    transition: all 500ms ease;
    -webkit-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
}

.subcribeform form input::placeholder {
    font-weight: 400;
    color: #BBBBBB;
}

.subcribeform form .btn {
    height: 55px;
    color: #fff;
    border-radius: 4px;
    font-size: 16px;
    letter-spacing: 1px;
    padding: 10px 30px;
    background-color: #000000;
    font-weight: 500;
    width: 200px;
    cursor: pointer;
}

.footerMenu {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
    margin: 50px 0px;
}

.footerWidget h4 {
    color: #ffffff;
    margin: 0px 0px 15px;
}

.footerWidget ul {
    margin: 0px;
    padding: 0px;
    list-style: none;
}

.footerWidget ul li a {
    font-size: 16px;
    color: #ffffff;
    display: block;
    padding: 0px 0px 5px;
}

.footerWidget ul li a:hover {
    color: #000000;
}

.footerInfoflex {
    display: flex;
    margin: 0px 0px 5px;
}

.footerrightAlign {
    margin-left: auto;
}

.footercenterAlign {
    display: flex;
    flex-direction: column;
    align-items: center;
}



.footerInfoflex a {
    font-size: 16px;
    color: #ffffff;
}

.Iconfinfo {
    font-size: 18px;
    color: #ffffff;
    margin-right: 10px;
}

.socialIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
    flex-flow: wrap;
    max-width: 297px;
}

.socialIcon a {
    color: #000000;
    font-size: 20px;
}

.subfooter p {
    text-align: center;
    color: #ffffff;
    margin: 30px 0px 0px;
}

.img-foot {
    margin-bottom: 15px;
    max-width: 297px;
}

.socialIcon i {
    color: #fff;
}

/*footer*/

/*contact page*/
.InnerPageBanner {
    padding: 120px 0px 170px;
    margin: 0px 0px 25px;
    position: relative;
    background-image: url(../../public/images//bannerlayerimg1.png);
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: cover;
}

.InnerPageBanner h1 {
    text-align: center;
    color: #ffffff;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 35px;
    max-width: 800px;
    margin: 0 auto;
}


/*=====Contact us page===*/
.contactusPage {
    padding: 30px 0px 70px;
}

.contactusInforow {
    /* display: grid;
    grid-template-columns: repeat(3, 1fr); */
    display: flex;
    gap: 30px;
    margin: 0px 0px 40px;
    justify-content: center;
}

.ContactusInfoBox {
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.08);
    background-color: #fff;
    padding: 37px 23px;
    max-width: 100%;
    width: 100%;
}

.ContactusInfoBox .infodflex {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.ContactusInfoBox .infodflex .infodflexIcon {
    margin: 0px 0px 15px;
}

.ContactusInfoBox .infodflex .infodflexIcont h4 {
    color: #000000;
    margin: 0px 0px 5px;
}

.contactusPage .formDivarea .formrow {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0px 15px;
}

.contactusPage .locationIframeDiv iframe {
    min-height: calc(262px)
}


/*form section*/
.formgroup {
    width: 100%;
    margin: 0px 0px 15px;
}

.formgroup input,
.formgroup select,
.TextAreaDiv textarea {
    border: 1px solid #c8c8c8;
    border-radius: 5px;
    color: #202020;
    background-color: #fff;
    padding: 10px;
    font-size: 15px;
    font-weight: 400;
    width: 100%;
    outline: none;
    height: 45px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.formgroup select {
    background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%23c8c8c8' class='bi bi-chevron-down' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center right 10px;
    background-size: 14px;
}

.formgroup select:focus {
    background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%232d616f' class='bi bi-chevron-down' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708'/%3E%3C/svg%3E");
}

.formgroup input::placeholder,
.TextAreaDiv textarea::placeholder {
    color: #202020;
}

.formgroup input:focus,
.formgroup select:focus,
.TextAreaDiv textarea:focus {
    border-color: #36884C;
}

.formcta {
    margin: 10px 0px 0px;
}

.formarea .formcta input.cta {
    display: table;
    padding: 12px 40px;
}

.formcta input.cta {
    border: none;
    padding: 10px 40px;
    letter-spacing: 1px;
}


.FormSectionrow {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
}

.TextAreaDiv textarea {
    height: 90px;
}

.FormSection {
    background-color: #f7f7f7;
    padding: 40px 0px;
}

.FormSectionBox {
    height: 100%;
    border-top: 3px solid #36884C;
    border-bottom: 3px solid #36884C;
    padding: 30px;
    background: #fff;
    width: 100%;
    box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.1);
}

.FormSectionBox .imgwrapper {
    height: 100%;
}

.FormSectionBox {
    height: 100%;
}

.infodflex {
    display: flex;
    align-items: flex-start;
}

.infodflex .infodflexIcon {
    width: 45px;
    min-width: 45px;
    height: 45px;
    min-height: 45px;
    background: #36884C;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    transition: all 0.3s ease-in-out;
    margin-right: 15px;
}

.infodflex .infodflexIcon i {
    font-size: 20px;
    color: #ffffff;

}

.infodflex .infodflexIcont h4 {
    font-size: 20px;
    color: #000000;
    margin: 0px;
}

.infodflex .infodflexIcont p,
.infodflex .infodflexIcont p a {
    color: #4C4C4C;
    font-size: 15px;
}

.locationIframeDiv iframe {
    width: 100%;
    height: 488px;
}



/*form section*/
/*=====Contact us page===*/


/*About us page*/
.MoneyComplicatedrow.secrow .MoneyComplicatedLhs {
    margin-left: 50px;
    margin-right: 0px;
}

.Abouttopsec .backcurcleimg {
    top: -15px;
    position: relative;
}

.ourvisionmissionsecrow {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
    margin: 70px 0px;
}

.ourvisionmissionBox {
    background: #FFF;
    box-shadow: 0px 0px 7.7px 0px rgba(0, 0, 0, 0.25);
    text-align: center;
    padding: 30px;
}

.ourvisionmissionBox h4 {
    margin: 25px 0px;
    font-size: 24px;
}

.ourvisionmissionBox p {
    margin: 0px;
}

.coreValuerow {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 0;
    background-color: #36884C;
}

.coreveluBox {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 15px;
    flex-direction: column;
    gap: 10px;
    -webkit-transition: all 0.25s ease;
    transition: all 0.25s ease
}

.corevaluecountdiv {
    width: 40px;
    min-width: 40px;
    height: 40px;
    min-height: 40px;
    background-color: #36884C;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: #fff;
    border-radius: 50%;
    -webkit-transition: all 0.25s ease;
    transition: all 0.25s ease
}

.corevaluetextdiv {
    color: #fff;
    font-size: 22px;
    font-weight: 500;
}




.coreveluBox:hover {
    background-color: #36884C;
    cursor: pointer;
}

.coreveluBox:hover .corevaluecountdiv {
    background-color: #fff;
    color: #36884C;
}

.active .coreveluBox .corevaluecountdiv {
    background-color: #fff;
    color: #36884C;
}

.active .coreveluBox {
    background-color: #36884C;
    cursor: pointer;
}


.coreValue {
    margin: 0px 0px 50px;
}

.coreValue .titleh2 {
    margin: 0px 0px 30px;
}

.coreValue .MoneyComplicatedrow.secrow {
    padding: 60px 30px 30px;
}

.coreValueIn {
    background: #FFF;
    box-shadow: 0px 0px 10.3px 0px rgba(0, 0, 0, 0.25);
}


/*for react*/
.TabsArea .nav {
    background-color: #6FC143;
    white-space: nowrap;
    overflow-y: auto;
    flex-flow: row;
}

.TabsArea .nav::-webkit-scrollbar {
    display: none;
}

.TabsArea .nav li {
    flex-basis: 20%;
    flex: 0 0 20%;
}


.TabsArea .tab-content {
    padding: 40px;
    min-height: 420px;
    display: flex;
    align-items: center;
}

.TabsArea {
    margin: 0px 0px 50px;
}

.TabsArea #tab-carousel {
    background: #FFF;
    box-shadow: 0px 0px 10.3px 0px rgba(0, 0, 0, 0.25);
}


/*for react*/
/*About us page*/




/*Blog page*/
.BlogPage {
    background-color: #fff;
    padding: 0px 0px 50px;
}

.imgwrapper {
    margin-bottom: 0px;
    position: relative;
    /* cursor: pointer; */
    overflow: hidden;
    transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s;
    /* border-radius: 4px; */
}

.sixrationine {
    padding-bottom: 56.25%;
    display: block;
}

.imgwrapper img,
.imgwrapper iframe,
.imgwrapper video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-transition: all 0.25s ease;
    transition: all 0.25s ease;
    object-fit: cover;
    object-position: top;
}

.Blogrow {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
}

.BlogcontentDiv {
    border-radius: 4px;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, .16);
    background-color: #fff;
    transition: transform .5s ease-in-out 25ms;
    overflow: hidden;
}

.BlogcontentDiv:hover {
    transform: translate(0) translateY(-10px);
}

.BlogContent h3 {
    font-size: 18px;
    color: #000;
    font-weight: 500;
}

.BlogContent h3 a {
    text-decoration: none;
    color: #000;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
}

.BlogContent p {
    font-size: 15px;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    margin: 0px;
}

.BlogpostDate {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
}

.BlogpostDate div {
    font-size: 14px;
    color: #4C4C4C;
    font-weight: 400;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.BlogpostDate div span {
    font-weight: 500;
    color: #000;
}

.BlogContent {
    padding: 15px;
    display: flex;
    flex-direction: column;
    height: calc(100% - 200px);
    justify-content: space-between;
}

.BlogpostDate {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 15px;
    margin-left: -15px;
    margin-right: -15px;
    padding: 0px 15px 8px;
    border-bottom: 1px solid #dbdbdb;
}

.DateDiv {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%234C4C4C' class='bi bi-calendar-event' viewBox='0 0 16 16'%3E%3Cpath d='M11 6.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5z'/%3E%3Cpath d='M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: left 2px;
    padding-left: 20px;
    background-size: 15px;
}

.BlogcontentDiv .cta {
    padding: 10px 20px;
    font-size: 15px;
    margin: 15px 0px 0px;
}

.BlogcontentDiv .imgwrapper {
    height: 200px;
    min-height: 200px;
}





/*Single blog page*/
.SingleBlogrow {
    display: flex;
}

.SingleBlogrow img {
    border-radius: 4px;
}

.SingleblogLhs {
    width: calc(70% - 30px);
    margin-right: 30px;
}

.SingleblogRhs {
    width: 30%;
}

/*search bar*/
.BlogSearchbar form {
    display: flex;
    border: 1px solid #131313;
    border-radius: 4px;
    overflow: hidden;
}

.BlogSearchbar form input {
    height: 45px;
    border: 0px;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 14px;
    border-radius: 0px;
    background-color: transparent;
    border-radius: 30px;
    outline: none;
    width: calc(100% - 80px);
    font-weight: 400;
    color: #313131;
    transition: all 500ms ease;
    -webkit-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
}

.BlogSearchbar form input::placeholder {
    font-weight: 400;
    color: #313131;
}

.BlogSearchbar form .searchbtn {
    height: 45px;
    width: 80px;
    min-width: 80px;
    color: #fff;
    border-radius: 0;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 1px;
    padding: 10px 35px;
    background-color: #131313;
    border-radius: 0px;
    font-weight: 500;
    cursor: pointer;
    border: 0px;
}

/*search bar*/

.recentPostWidget {
    margin: 30px 0px 0px;
}

.SingleblogRhs h4 {
    background-color: #131313;
    color: #fff;
    font-size: 20px;
    padding: 12px 8px;
    margin: 0px 0px 15px;
}

.postwidgetflex {
    display: flex;
    margin: 0px 0px 8px;
}

.postwidgetflex .imgwrapper {
    width: 110px;
    min-width: 110px;
    height: 83px;
    min-height: 83px;
    margin: 0px 10px 0px 0px;
    overflow: hidden;
}

.postwidgetflex p {
    margin: 0px 0px 4px;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    font-size: 15px;
}

.recentpostcontent p a {
    color: #4C4C4C;
}

.recentpostcontent .monthdayyear p a {
    color: #000;
    font-size: 15px;
}

.recentPostWidgetrow .postwidgetflex:not(:last-child) {
    border-bottom: 1px solid #dbdbdb;
    padding: 0px 0px 8px;
}

.SingleblogLhs .BlogcontentDiv p {
    margin: 0px 0px 10px;
}

.SingleblogLhs .BlogcontentDiv:hover {
    transform: translate(0) translateY(0px);
}

.SingleblogLhs .BlogcontentDiv .imgwrapper {
    height: auto;
}

.SingleblogLhs .BlogcontentDiv .BlogContent {
    height: auto;
}

/*Single blog page*/

/*Blog page*/


/*privacy policy page*/
.newcontentprivacypolicy h3 {
    font-size: 20px;
    font-weight: 700;
    color: #000;
    margin: 0px 0px 15px;
}

.newcontentprivacypolicy p.pb-4 {
    padding: 0px !important;
}

.tableui {
    margin: 0px 0px 20px;
    overflow: auto;
}

.tableui table {
    border-collapse: collapse !important;
    width: 100%;
}

.tableui table th,
.tableui table td {
    border: 1px solid #dbdbdb;
    padding: 8px;
    white-space: nowrap;
    font-size: 15px;
}

.PrivacyPolicypagein {
    border-radius: 40px;
    padding: 40px;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, .16);
    margin: 0px 0px 50px;
}

/*privacy policy page*/



/*notfoundpage*/
.Pagenotfoundmain {
    border-top: 1px solid #dbdbdb;
}

.notfoundpage {
    max-width: 700px;
    margin: 0 auto;
    padding: 50px 0px;
    text-align: center;
}

.notfoundpageImg {
    max-width: 500px;
    margin: 0 auto;
}

.notfoundpageText .cta {
    margin: 0 auto;
    margin-top: 20px;
}

.notfoundpageText {
    margin: 20px 0px 0px;
}






/*responsive*/
.formobileimg {
    display: none;
}


.gaginationDiv {
    justify-content: center;
    display: flex;
    margin: 30px 0px 0px;
    flex-flow: wrap;
}

.gaginationDiv button {
    border: 1px solid #dbdbdb;
}

.gaginationDiv button.text-white {
    background-color: #36884c;
}

.text-size p {
    font-size: 21px;
}

.Headercta-mobile {
    display: none;
}

.Headercta-mobile .cta {
    text-decoration: none;
    transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
    background-color: #36884C;
    padding: 12px 25px;
    border-radius: 8px;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    display: table;
}

.treesection {
    background: #e2ffd1;
    color: #fff;
    padding: 10px;

    width: 300px;
    border-radius: 30px;
}

.text-black {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.count {
    font-size: 60px;
    color: #65320b;
    padding-left: 10px;
    font-weight: 700 !important;
}

/* thanYou Page */
.thankyou-wrapper {
    max-width: 600px;
    margin: 0 auto;
    display: table;
    text-align: center;
    padding: 50px 0px;
}

.thankyou-wrapper p {
    color: #000;
    font-weight: 500;
    font-size: 22px;

}

.thankyou-wrapper button {
    background-color: #36884C;
    padding: 10px 15px 12px;
    text-decoration: none;
    color: #fff;
    display: table;
    margin: 0 auto;
    border-radius: 4px;
    border: none;
}








/*============pricing page==============*/
/* .pricingpage{
    background-image: url("data:image/svg+xml,%0A%3Csvg width='700' height='713' viewBox='0 0 700 713' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='700' height='713' fill='url(%23paint0_linear_31_430)' fill-opacity='0.83'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_31_430' x1='350' y1='-146.5' x2='350' y2='924.5' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0.2' stop-color='%2336884C'/%3E%3Cstop offset='0.65'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position:bottom;
    background-size: cover;
} */
.pricingpageWrap {
    max-width: 700px;
    margin: 50px auto;
    padding: 120px;
    border-radius: 20px;
    background-image: url("data:image/svg+xml,%0A%3Csvg width='700' height='713' viewBox='0 0 700 713' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='700' height='713' fill='url(%23paint0_linear_31_430)' fill-opacity='0.83'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_31_430' x1='350' y1='-146.5' x2='350' y2='924.5' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0.2' stop-color='%2336884C'/%3E%3Cstop offset='0.65'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
}

.pricingpageWrapInner {
    background-color: #fff;
    border-radius: 20px;
    padding: 60px;
}

.pricingtop {
    text-align: center;
}

.pricingtop h2 {
    color: #36884C;
    font-size: 35px;
    font-weight: 700;
}

.pricingtop p {
    color: #000;
    margin: 12px 0px 35px;
    font-weight: 500;
    font-size: 20px;
}


.pricingAnnual {
    position: relative;
}

.annualtoprow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    background-color: #36884C;
    padding: 10px;
    border-radius: 3px;
    border-bottom-left-radius: 0px;
    position: relative;
}

.annualtoprowText a {
    font-size: 16px;
    color: #fff;
    font-weight: 600;
}

.annualsavingrow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0px 0px 0px;
}

.annualsavingrow .paidannual p {
    margin: 0px;
}

.savingtagin {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 2px;
    background-color: #f6c91d;
    padding: 4px 20px 4px 20px;
    font-size: 16px;
    color: #000;
    font-weight: 600;
    position: relative;
    left: -14px;
    bottom: 0px;
}

.savingtagin::after {
    content: "";
    background-image: url("data:image/svg+xml,%0A%3Csvg width='16' height='9' viewBox='0 0 16 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16 0.917969V8.91797H0L16 0.917969Z' fill='%23f6c91d'/%3E%3C/svg%3E%0A");
    position: absolute;
    width: 15px;
    height: 15px;
    top: -8px;
    left: 0px;
    background-repeat: no-repeat;
    background-size: 100%;
}

.Monthlyplanrow {
    padding: 10px;
    background-color: #36884C;
    border-radius: 3px;
    margin: 30px 0px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.Monthlyplanrow a {
    color: #fff;
}

.Monthlyplanrow div {
    font-size: 16px;
    color: #fff;
    font-weight: 600;
}

.smalldetailsText {
    text-align: center;
    font-size: 16px;
    color: #000;
    font-weight: 400;
    padding: 30px 0px;
}


.pricingboxcta .cta {
    margin: 0 auto;
    background-color: #36884C;
    text-align: center;
    color: #fff;
    border-radius: 30px;
    padding: 8px 20px;
    filter: drop-shadow(0px 5px 7px rgba(0, 0, 0, 0.25));
}

.pricingboxcta .cta:hover {
    background-color: #000;
    color: #fff;
}

@media screen and (max-width:767px) {
    .pricingpageWrap {
        padding: 30px 0px;
        background-image: none;
    }

    .pricingtop h2 {
        font-size: 25px;
    }

    .pricingtop p {
        color: #000;
        margin: 10px 0px 20px;
        font-weight: 500;
        font-size: 18px;
    }

    .Monthlyplanrow {
        margin: 20px 0px 0px;
    }

    .smalldetailsText {
        padding: 20px 0px;
    }

    .annualtoprowText,
    .savingtagin,
    .Monthlyplanrow div,
    .pricingboxcta .cta {
        font-size: 14px;
    }

    .pricingpageWrapInner {
        padding: 20px;
    }

    .pricingpage {
        background-image: url("data:image/svg+xml,%0A%3Csvg width='700' height='713' viewBox='0 0 700 713' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='700' height='713' fill='url(%23paint0_linear_31_430)' fill-opacity='0.83'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_31_430' x1='350' y1='-146.5' x2='350' y2='924.5' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0.2' stop-color='%2336884C'/%3E%3Cstop offset='0.65'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-position: bottom;
        background-size: cover;
    }


}

/*============pricing page==============















/*=======media query======*/
.mobileNone {
    display: none;
}

.desktopNone {
    display: block;
}

@media only screen and (min-width:992px) {
    .mobileNone {
        display: block;
    }

    .desktopNone {
        display: none;
    }

    .dropdown:hover .dropdown-menu {
        display: block;
        margin-top: 0;
        /* remove the gap so it doesn't close */
    }

    .dropdown-menu {
        box-shadow: 0 1px 6px 0 rgba(0, 0, 0, .16);
    }




}

@media (min-width: 992px) {
    .container {
        max-width: 1100px !important;
    }
}

@media (min-width: 1200px) {
    .container {
        max-width: 1200px !important;
    }
}

@media screen and (min-width:1151px) {}

@media screen and (max-width:1150px) {
    .Imgback {
        position: absolute;
        top: 0;
        right: 0;
    }

    .ImgFront {
        position: absolute;
        top: -20px;
        left: 2px;
    }

}

@media screen and (max-width:1199px) {}



@media screen and (max-width:1024px) {
    .twocolumnrow {
        flex-direction: column;
    }

    .ContentSec {
        width: calc(100% - 0px);
        min-width: calc(100% - 0px);
        padding-bottom: 0px;
        text-align: center;

    }

    .HeroBanner p {
        margin: 0 auto;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .ImgSection {
        width: calc(100% - 0px);
        min-width: calc(100% - 0px);
    }

    .HeroBanner h1 {
        font-size: 40px;
    }

    .ImgLayeranimationIn {
        max-width: 500px;
        margin: 0 auto;
    }

    .ImgLayeranimationInner {
        max-width: 500px;
        min-width: 500px;
        max-height: 500px;
        min-height: 500px;
    }

    .ImgLayeranimation {
        display: none;
    }

    .formobileimg {
        display: block;
    }

    .formobileimg {
        max-width: 500px;
        margin: 0 auto;
        margin-top: 30px;
    }

    .HeroBanner .cta {
        margin: 0 auto;
    }



}



@media screen and (max-width:991px) {
    .container {
        max-width: 100% !important;
    }

    .dropdown-menu {
        border: 1px solid #dbdbdb !important;
    }

    .HeaderInner ul li a {
        padding: 5px 12px;
    }

    .titleh2 h2 {
        font-size: 30px;
    }

    .titleh2 {
        margin: 0px 0px 20px;
    }

    .WhyNAB .titleh2,
    .tophd {
        margin: 0px 0px 30px;
    }

    .HowItWorksection {
        padding: 40px 0px;
    }

    .footerIn {
        padding: 40px 0px 20px;
        background-color: #36884C;
        background-image: none;
    }

    .Rulesforstress {
        padding-bottom: 40px;
    }

    .Blogrow {
        grid-template-columns: repeat(2, 1fr);
    }

    .SingleBlogrow {
        flex-direction: column;
    }

    .SingleblogLhs {
        width: 100%;
        min-width: 100%;
        margin: 0px 0px 30px;
    }

    .SingleblogRhs {
        width: 100%;
        min-width: 100%;
    }

    .PrivacyPolicypagein {
        padding: 30px 15px;
    }

    .TabsArea .nav li {
        width: 150px;
        min-width: 150px;
    }

    .text-size p,
    .OverlayText p {
        font-size: 16px;
    }

    .Flipbox h4 {
        font-size: 20px;
    }

    .app-store {
        margin: 40px 0px 0px;
    }

}


@media screen and (max-width:767px) {
    .MoneyComplicatedrow {
        flex-direction: column-reverse;
    }

    .MoneyComplicatedLhs {
        width: 100%;
        min-width: 100%;
        margin-right: 0px;
        margin-top: 40px;
    }

    .MoneyComplicatedRhs {
        width: 100%;
        min-width: 100%;
        text-align: center;
    }

    .MoneyComplicatedRhs .cta {
        margin: 0 auto;
    }

    .MoneyComplicated {
        background-color: #ffffff;
        padding: 0px 0px 40px;
    }

    .HeroBanner {
        margin: 0px;
    }

    .MoneyComplicatedImg {
        position: relative;
        max-width: 500px;
        margin: 0 auto;
    }

    .HowItWorksectionrow {
        flex-direction: column-reverse;
    }

    .HowItWorkRhs {
        width: 100%;
        min-width: 100%;
    }

    .nabImg {
        max-width: 500px;
        margin: 0 auto;
    }

    .nabImgFront {
        bottom: 0;
        left: 54px;

    }




    .HowItWorkLhs {
        width: 100%;
        min-width: 100%;
        margin-top: 50px;
    }

    .HowItWorkiconText {
        margin: 0px 0px 25px;
        max-width: 100%;
    }

    .WhyNABrow {
        grid-template-columns: repeat(2, 1fr);

    }

    .FlipboxIcon {
        max-width: 170px;
        margin: 0 auto 20px;
    }

    .footerTop {
        flex-direction: column;
        gap: 0px;
    }

    .flogo {
        width: 100%;
        min-width: 100%;
        margin: 0px 0px 30px;
    }

    .subcribeform {
        width: 100%;
        min-width: 100%;
    }

    .footerMenu {
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
        margin: 30px 0px;
    }

    .contactusInforow {
        grid-template-columns: repeat(2, 1fr);
    }

    .FormSectionrow {
        grid-template-columns: repeat(1, 1fr);
    }

    .FormSectionBox {
        padding: 20px 15px;
    }

    .contactusPage {
        padding: 20px 0px 50px;
    }

    .InnerPageBanner {
        padding: 70px 0px 90px;
    }

    .InnerPageBanner h1 {
        font-size: 30px;
    }

    .MoneyComplicatedrow.secrow {
        flex-direction: column;
        margin: 40px 0px 0px;
    }

    .ourvisionmissionsecrow {
        margin: 40px 0px;
        grid-template-columns: repeat(1, 1fr);
    }

    .ourvisionmissionBox h4 {
        margin: 15px 0px;
    }

    .coreValuerow {
        grid-template-columns: repeat(3, 1fr);
    }

    .corevaluetextdiv {
        font-size: 16px;
    }

    .MoneyComplicatedrow.secrow .MoneyComplicatedLhs {
        margin-left: 0px;
    }

    .coreValue .MoneyComplicatedrow.secrow {
        padding: 0px 30px 30px;
    }

    .TabsArea .tab-content {
        padding: 0px 30px 30px;
    }

    .coreveluBox {
        padding: 10px;
    }

    .footercenterAlign {
        align-items: flex-start;
    }

    .footerrightAlign {
        margin-left: inherit;
    }


}



@media screen and (max-width:575px) {

    .WhyNABrow,
    .Rulesforstressrow,
    .footerMenu,
    .contactusInforow,
    .contactusPage .formDivarea .formrow {
        grid-template-columns: repeat(1, 1fr);
    }

    .subcribeform form {
        flex-direction: column;
    }

    .contactusInforow {
        flex-direction: column;
        align-items: center;
    }

    .subcribeform form input,
    .subcribeform form .btn {
        width: 100%;
    }

    .FlipBoxparent {
        min-height: 310px;
    }

    .Headercta {
        display: none;
    }

    .Blogrow {
        grid-template-columns: repeat(1, 1fr);
    }

    .coreValuerow {
        grid-template-columns: repeat(2, 1fr);
    }

    .Headercta-mobile {
        display: block;
    }

}

.freetrail {
    margin-right: 10px;
}


/* dynamic form css */
.preview-form-wrapper .preview-form-title {
    font-size: 30px !important;
}

/* PrivacyPolicy.css */

.privacy-policy table tr {
    border-bottom: 1px solid #ccc;
}

.privacy-policy table th,
.privacy-policy table td {
    border-right: 1px solid #ccc;
    padding: 10px;
}

.privacy-policy table {
    border: 1px solid #ccc;
    margin-bottom: 40px;
}

.center-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    /* Full viewport height */
    text-align: center;
}

.annualtoprowText.annulatextLhs {
    Font-size: 16px;
    color: #fff;
    font-weight: 600;
}

.annualtoprowText.annualtextRhs {
    Font-size: 16px;
    color: #fff;
    font-weight: 600;
}